<template>
  <div>
    <div class="list-g">
      <el-tabs v-model="activeName" @tab-click="setState" type="card">
        <el-tab-pane label="成功" name="1"></el-tab-pane>
        <el-tab-pane label="失败" name="-1"></el-tab-pane>
        <el-tab-pane label="空凭据" name="0"></el-tab-pane>
        <el-tab-pane label="搜索" name="10">
          <div class="list-g" style="width:300px;margin-top:30px">
            <label for>订单号</label>
            <el-input type="text" class="inputbox-g" v-model="orderSn" size="mini"></el-input>
            <el-button type="primary" @click="search" size="mini">搜索</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-pagination
      :page-size="count"
      :pager-count="9"
      :current-page.sync="page"
      layout="total, prev, pager, next"
      :total="total"
      hide-on-single-page
      @current-change="handleCurrentChange"
    ></el-pagination>
    <div v-for="(noti,index) in notifyorders" :key="index" class="container">
      <el-descriptions :title="noti.type" :column="4" border style="margin:20px 0;" size="mini">
        <el-descriptions-item
          v-for="(value, key) in tableList"
          :key="key"
          :label="value"
          contentStyle="min-width:100px"
        >{{ noti[key]}}</el-descriptions-item>
        <el-descriptions-item
          v-for="(value, key) in noti.data"
          :key="key"
          :label="key"
          contentStyle="min-width:100px"
        >{{ value}}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions title="回调单" :column="5" border size="mini">
        <el-descriptions-item
          v-for="(value, key) in noti.notifyInfo"
          :key="key"
          :label="key"
        >{{ value}}</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
import { getNotifyorders } from "@/api/api";
export default {
  data() {
    return {
      page: 1,
      total: 0,
      status: "1",
      activeName: "1",
      notifyorders: [],
      count: 10,
      orderSn: "",
      searchResult: {},
      show: false,
      tableList: {
        type: "类别",
        cashChannel: "支付渠道",
        status: "状态",
        order_sn: "发起凭据号",
        out_trade_no: "订单号",
        id: "ID",
        genTime: "时间"
      }
    };
  },
  created() {
    this.getNotifyorders();
  },
  methods: {
    getNotifyorders() {
      const that = this;
      getNotifyorders({
        page: that.page,
        count: that.count,
        status: that.status,
        orderSn: that.orderSn
      }).then(res => {
        that.notifyorders = res.data.notifyorders;
        that.total = res.data.total;
      });
    },

    search() {
      const that = this;
      if (!that.orderSn) {
        this.$message({
          type: "warning",
          message: "空搜索"
        });
      } else {
        this.getNotifyorders();
      }
    },

    setState(val) {
      if (val.name < 10) {
        this.status = val.name;
        this.page = 1;
        this.orderSn = null;
        this.getNotifyorders();
      } else {
        this.status = null;
        this.notifyorders = [];
      }
    },

    handleCurrentChange(e) {
      this.pageNumber = e;
      this.getNotifyorders();
    }
  }
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  margin: 20px 0;
  border: 1px solid #555;
  padding: 20px;
}
.red {
  background: red;
}
</style>